<template>
    <div style="height: 100%">
        <a-layout style="height: 100%">
            <a-layout-content style="height: 100%">
                <div class="yj-conten" style="min-height: calc(100% - 48px);background-color: #fff; width: calc(100% - 32px);">
                    <a-card title="增加物料" style="width: 100%; min-height: 100%" :bordered="false">
                        <a-form-model layout="horizontal" style="width: 800px" ref="ruleForm"
                                      :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">

                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>物料数量：</label>
                                    <a-input placeholder="数量" v-model="MCount" :disabled="disabled" :min="1" type="number" />
                                </a-col>
                            </a-row>



                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>物料类型：</label>
                                    <br />
                                    <a-select :disabled="disabled" v-model="UsingType" style="width: 700px">
                                        <a-select-option :value="0" selected="selected">海报</a-select-option>
                                        <a-select-option :value="1">宣传页</a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>
                            <a-row style='margin:20px 0 0 0'>
                                <a-col :span="24">
                                    <label>二维码类型：</label>
                                    <br />
                                    <a-select :disabled="disabled" v-model="QRCodeType" style="width: 700px">
                                        <a-select-option :value="0" selected="selected">一物一码H5</a-select-option>
                                        <a-select-option :value="1">H5图层码</a-select-option>
                                        <a-select-option :value="2">一物一码小程序码</a-select-option>
                                        <a-select-option :value="3">小程序图层码</a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>

                            <!--<a-row style="margin-top:100px">
                                <a-col :span="24">
                                    <a-button @click="showAwardPoolConfig()" :disabled="disabled" icon="plus">选择活动</a-button>
                                </a-col>
                            </a-row>
                            <a-row v-for="item in TurnTable" :key="item.ID" :gutter="[10, 10]" style="border-bottom: 1px solid #f9f9f9; margin-top: 25px">
                                <a-col :span="5">
                                </a-col>
                                <a-col :span="16"><strong>{{ item.Name }}</strong></a-col>
                                <a-col :span="3">
                                    <a-button size="small" type="danger" @click="deleteTurnTableConfig(item.ID)" :disabled="disabled">删除</a-button>
                                </a-col>
                            </a-row>-->


                        </a-form-model>
                    </a-card>
                    <a-card :bordered='false'>
                        <a-button type="primary" :disabled="disabled" @click="SubmitModel()">
                            保存
                        </a-button>
                    </a-card>
                </div>
            </a-layout-content>
        </a-layout>
        <!--<div v-if="TurnTableVisible">
            <TurnTableConfig :hide="TurnTableConfighide" :IDList="TurnTable" :SelectType="SelectType" :ID="TurnID"></TurnTableConfig>
        </div>-->


    </div>
</template>
<script src="https://api.map.baidu.com/api?v=2.0&ak=E8f3c743837925ce120eb93417f478db"></script>
<script>
	import http from "@/Plugin/Http.js";
    //import TurnTableConfig from "@/Views/MaterialModule/components/MinterTurnTable";
    export default {
        name: "",
        data() {
            return {
                disabled: false,
                MCount: "",
                //TurnTableVisible: false,
                TurnID: 0,
                //TurnTable: [],
                UsingType: "",
                QRCodeType:"",
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 19 },
                    },
                },
                SelectType: "radio",
              
            };
        },
        props: {
            //组件属性
        },
        methods: {
            //方法
            //showAwardPoolConfig: function () {
            //    var self = this;
            //    self.TurnTableVisible = true;
            //},

            //deleteTurnTableConfig(e) {
            //    console.log(e);
            //    var self = this;
            //    self.TurnID = 0;
            //    self.TurnTable = [];
            //},

            //TurnTableConfighide: function (e, info) {
            //    var self = this;
            //    if (e == 1) {
            //        if (info.length > 0) {
            //            self.TurnID = info[0].TurnID;
            //            self.TurnTable = info;
            //        } else {
            //            self.TurnID = 0;
            //            self.TurnTable = [];
            //        }
            //    }
            //    self.TurnTableVisible = false;
            //},


            SubmitModel() {
                var self = this;
                console.log("self.TurnID", self.TurnID);
                if (self.checkinput()) {
                    var op = {
                        url: "/MarketingModule/Promote/AddQRMaterialOrder",
                        data: {
                            MaterialOrderID: self.ID,
                            MCount: self.MCount,
                            TurnTableID: 0,
                            UsingType: self.UsingType,
                            QRCodeType:self.QRCodeType,
                        },
                        OnSuccess: function (response) {
                            self.$message.success("保存成功");
                            self.$router.push({
                                name: "PromoteMaterial_List",
                            });
                        },
                    };
                    http.Post(op);
                }
                else {
                    return false;
                }
            },

            checkinput: function () {
                var rt = true;
                var self = this;
                if (self.MCount == "") {
                    rt = false;
                    self.$message.error("请输入物料数量！");
                }
                return rt;
            },

            //checkMinterTurn: function () {
            //    var rt = true;
            //    var self = this;
            //    if (self.TurnID == 0) {
            //        rt = false;
            //        self.$message.error("请选择活动！");
            //    }
            //    return rt;
            //},


           loadData: function () {
               var self = this;
               self.ID = self.$route.query.ID;
               console.log("self.ID",self.ID);
                var op = {
                    url: "/MarketingModule/Promote/GetProMaterialInfo",
                    data: {
                        MaterialOrderID: self.ID,
                    },
                    OnSuccess: function (data) {
                        console.log("data", data.data);
                        console.log(data.data.MCount);
                        if (self.ID != 0) {
                            self.MCount = data.data.MCount;
                            self.TurnID = data.data.TurntableID;
                            console.log("self.MCount", self.MCount);
                            //self.TurnTable = data.data.TurnTableInfo;
                            self.UsingType = data.data.UsingType;
                            self.QRCodeType=data.data.QRCodeType
                        }
                    },
                };
                http.Post(op);
            },





        },
        computed: {
            //计算属性
        },
      
        components: {
            //组件
            //TurnTableConfig,
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            if (self.$route.query.disabled == 1) {
                self.disabled = true;
            }
            self.ID = self.$route.query.ID;
            self.loadData();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    };
</script>
<style type="text/css">
    .bm-view {
        width: 100%;
        height: 600px;
    }

    #suggestId {
        width: 500px;
        top: -68px;
        margin-left: 100px;
    }

    #map_title {
        position: absolute;
        z-index: 9999;
        left: 23px;
        right: 23px;
        height: 60px;
        background-color: #09f;
        padding-top: 10px;
        padding-left: 20px;
        color: white;
    }

    .tangram-suggestion {
        z-index: 199999
    }

    #map_sel {
        position: absolute;
        z-index: 9999;
        right: 106px;
        top: 92px;
    }

    #map_btns {
        position: absolute;
        z-index: 9999;
        bottom: 30px;
        width: 100%;
    }

        #map_btns input[type='button'] {
            width: 120px;
            height: 45px;
            margin-top: 20px;
            color: #fff;
            border: none;
            border-radius: 30px;
            opacity: 0.8;
        }

    .map_save {
        background-color: green;
        margin-right: 40px;
    }

    .map_cancel {
        background-color: #f15b5b;
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
    }
</style>
